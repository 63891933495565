import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { requestOTP } from "../../api/requestOTP";
import { validateOTP } from "../../api/validateOTP";
import illustration from "../../assets/img/email.png";
import Button from "../../utils/components/Button";
import { Gap } from "../../utils/components/Gap";
import Input from "../../utils/components/Input";
import ValidateCode from "../../utils/components/ValidateCode";
import Layout from "../Layout";

interface Email extends View {
  handleNext: () => void;
  inquiryId: string;
  updateData: (value: any) => void;
}

const storedCodeRequestsLeft = parseInt(
  localStorage.getItem("emailCodeRequestsLeft") ?? "5"
);

const Email = ({ handleNext, inquiryId, updateData }: Email) => {
  const [isOTPRequested, setOTPRequested] = useState(false);
  const [isCodeValid, setIsCodeValid] = useState(false);
  const [attemptsLeft, setAttemptsLeft] = useState(5);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [buttonText, setButtonText] = useState("Confirmar correo");
  const [timer, setTimer] = useState(30);
  const [codeRequestsLeft, setCodeRequestsLeft] = useState(
    storedCodeRequestsLeft
  );
  const [isRequesting, setIsRequesting] = useState(false);

  const { register, watch } = useForm();
  const emailValue = watch("email");

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isOTPRequested && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setButtonText("Reenviar código");
      // @ts-ignore
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isOTPRequested, timer]);

  useEffect(() => {
    localStorage.setItem("emailCodeRequestsLeft", codeRequestsLeft.toString());
    return () => localStorage.removeItem("emailCodeRequestsLeft");
  }, [codeRequestsLeft]);

  const isValidEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const generateEmail = () => {
    console.log("Generated Email");
  };

  const handleRequestOTP = async () => {
    setIsRequesting(true);
    try {
      const requested = await requestOTP("email")({
        email: emailValue,
        inquiryId,
      });
      if (!requested || requested.error) {
        const error = requested?.error ?? "Error al solicitar el código";
        throw Error(error);
      }

      setCodeRequestsLeft((prev) => prev - 1);
      setAttemptsLeft(5);
      setOTPRequested(true);
      setButtonText("Pedir código nuevamente");
      setButtonDisabled(true);
      let countDown = 30;
      const interval = setInterval(() => {
        setTimer(countDown--);
        if (countDown < 0) {
          clearInterval(interval);
          setButtonDisabled(false);
        }
      }, 1000);
    } catch (error) {
      console.log(error);
      // snackbar error
    } finally {
      setIsRequesting(false);
    }
  };

  const onNext = () => {
    updateData(emailValue);
    handleNext();
  };

  const content = (
    <div className="flex flex-col">
      <p>
        Llena el campo con tu correo electronico. Te enviaremos un código para
        confirmar que es tuyo.
      </p>
      <p>
        Si no tienes uno, haz click en “No tengo correo” . y te crearemos uno
      </p>

      <Gap s={24} />
      <Input
        label="Correo electrónico"
        placeholder="Ingresa tu correo aquí"
        type="email"
        disabled={isCodeValid}
        {...register("email")}
      />
      <Gap s={8} />
      {emailValue?.length ? (
        <Button
          variant="outline"
          onClick={handleRequestOTP}
          style={{ height: "40px" }}
          isLoading={isRequesting}
          disabled={
            !isValidEmail(emailValue) ||
            !codeRequestsLeft ||
            isButtonDisabled ||
            isCodeValid
          }
          size={"2"}
        >
          {buttonText} {timer > 0 && `(${timer})`}
        </Button>
      ) : (
        <Button
          variant="outline"
          onClick={generateEmail}
          style={{ height: "40px" }}
          disabled={emailValue?.length ? isValidEmail(emailValue) : false}
          size={"2"}
        >
          {"No tengo correo"}
        </Button>
      )}
      {isOTPRequested ? (
        <ValidateCode
          validateCode={validateOTP("email")}
          inquiryId={inquiryId}
          isCodeValid={isCodeValid}
          setIsCodeValid={setIsCodeValid}
          attemptsLeft={attemptsLeft}
          setAttemptsLeft={setAttemptsLeft}
        />
      ) : null}
    </div>
  );

  const button = {
    text: "Continuar",
    disabled: !isCodeValid,
    onNext,
  };

  return (
    <Layout
      title="Correo electrónico"
      content={content}
      button={button}
      illustration={illustration}
    />
  );
};

export default Email;
