import * as RadixToggleGroup from "@radix-ui/react-toggle-group";
import React, { Dispatch, SetStateAction } from "react";
import { Label } from "../shared";

const toggleGroupItemClasses =
  "hover:bg-violet3 color-mauve11 data-[state=on]:bg-violet6 data-[state=on]:text-violet12 flex h-[35px] w-0 flex-grow items-center justify-center bg-white text-base leading-4 first:rounded-l last:rounded-r focus:z-10 focus:shadow-[0_0_0_2px] focus:shadow-black focus:outline-none";

type ToggleGroup = {
  options: {
    value: string;
    node: string | React.ReactNode;
  }[];
  onValueChange: (value: string) => void | Dispatch<SetStateAction<string>>;
  value: string;
  label?: string;
};

const ToggleGroup = ({ options, onValueChange, value, label }: ToggleGroup) => (
  <>
    {label ? <Label>{label}</Label> : ""}
    <RadixToggleGroup.Root
      className="inline-flex bg-mauve6 rounded shadow-[0_2px_10px] shadow-blackA4 space-x-px"
      type="single"
      onValueChange={onValueChange}
      value={value}
    >
      {options.map(({ value, node }) => (
        <RadixToggleGroup.Item
          className={toggleGroupItemClasses}
          value={value}
          aria-label={value}
        >
          {node}
        </RadixToggleGroup.Item>
      ))}{" "}
    </RadixToggleGroup.Root>
  </>
);

export default ToggleGroup;
