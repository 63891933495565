import React from "react";
import { Gap } from "../../utils/components/Gap";
import { Container, Illustration, NextButton, Title } from "./components";

interface Layout {
  title: string;
  illustration: string;
  content: React.ReactNode;
  button: {
    onNext: () => void;
    text: string;
    disabled?: boolean;
  };
}

const Layout = ({ title, illustration, content, button }: Layout) => {
  return (
    <Container>
      <Gap s={24} />
      <Title>{title}</Title>
      <Gap s={24} />
      <Illustration src={illustration} />
      <Gap s={24} />
      {content}
      <div className="mt-auto w-full">
        <NextButton disabled={button.disabled} onClick={button.onNext}>
          {button.text}
        </NextButton>
      </div>
    </Container>
  );
};

export default Layout;
