import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { Gap } from "../../utils/components/Gap";
import Input from "../../utils/components/Input";
import Layout from "../Layout";
import UserProfileClass from "../Verification/UserProfile";

interface Profile extends View {
  verificationResult: VerificationResult;
}

const loadingGif =
  "https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif";

const Profile = ({ verificationResult, handleNext }: Profile) => {
  const [illustration, setIllustration] = useState(loadingGif);
  const user = new UserProfileClass(
    verificationResult.fields,
    verificationResult.inquiryId
  );

  const defaultValues = {
    name: user.name,
    lastName: user.lastName,
  } as const;

  const { register } = useForm<typeof defaultValues>({
    defaultValues,
    mode: "onChange",
  });

  useEffect(() => {
    const updateProfilePicture = async () => {
      const picture = await user.fetchProfilePicture();
      setIllustration(picture);
    };

    updateProfilePicture();
  }, []);

  const onNext = () => {
    handleNext();
  };

  const content = (
    <>
      <Input label="Nombres" {...register("name")} />
      <Gap s={8} />
      <Input label="Apellidos" {...register("lastName")} />
      <div className="flex-grow" />
      <p>
        Confirmo que los datos presentados son correctos y que yo soy la persona
        mostrada en la imagen
      </p>
    </>
  );

  const button = {
    onNext,
    text: "Confirmar",
    disabled: false,
  };

  return (
    <ProfileImageStyleWrapper>
      <Layout
        title="Verificar Datos"
        content={content}
        button={button}
        illustration={illustration}
      />
    </ProfileImageStyleWrapper>
  );
};

export default Profile;

export const ProfileImageStyleWrapper = styled.div`
  img {
    min-width: 140px;
    max-width: 140px;
    min-height: 140px;
    max-height: 140px;
    border-radius: 50%;
    object-fit: cover;
  }
`;
