import { Button as RadixButton } from "@radix-ui/themes";
import React, { forwardRef } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import { Color } from "../../../styles";
import { Container } from "./styles";

type ButtonProps = React.ComponentProps<typeof RadixButton> & {
  isLoading?: boolean;
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, size = "4", isLoading, ...props }, ref) => {
    props.disabled = props.disabled || isLoading;
    return (
      <Container>
        <RadixButton ref={ref} size={size} {...props}>
          {isLoading ? (
            <BeatLoader color={Color.secondary} size={8} />
          ) : (
            children
          )}
        </RadixButton>
      </Container>
    );
  }
);

export default Button;
