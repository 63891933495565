import { useState } from "react";
import illustration from "../../assets/img/work_type.png";
import { Gap } from "../../utils/components/Gap";
import Select from "../../utils/components/Select";
import Layout from "../Layout";

interface WorkType extends View {
  updateData: (value: any) => void;
}

const WorkType = ({ handleNext, updateData }: WorkType) => {
  const [disabled, setDisabled] = useState(true);
  const onNext = () => {
    handleNext();
  };

  const content = (
    <div className="w-full">
      <p>Selecciona el tipo de trabajo al que te dedicas.</p>
      <Gap s={24} />
      <Select
        label="Tipo de trabajo"
        options={[
          { value: "1", label: "Empleado" },
          { value: "2", label: "Independiente" },
          { value: "3", label: "Pensionado" },
          { value: "4", label: "Desempleado" },
        ]}
        onChange={(option) => {
          setDisabled(false);
          updateData(option);
        }}
      />
    </div>
  );

  const button = {
    onNext,
    text: "Confirmar",
    disabled,
  };

  return (
    <Layout
      illustration={illustration}
      title="Actividad Económica"
      content={content}
      button={button}
    />
  );
};

export default WorkType;
