import React, { useState } from "react";
import { InputWrapper, Label } from "../shared";
import { CustomFileUploadButton, FileNameDisplay, StyledInput } from "./styles";
interface InputProps {
  label: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  placeholder?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ label, type, onChange, ...props }, ref) => {
    const [fileName, setFileName] = useState("");
    const fileInputRef = React.useRef<HTMLInputElement>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        setFileName(file.name);
      }
      if (onChange) {
        onChange(event);
      }
    };

    const renderFileInput = () => (
      <>
        <StyledInput
          ref={e => {
            //@ts-ignore
            ref(e);
            //@ts-ignore
            fileInputRef.current = e;
          }}
          type="file"
          {...props}
          multiple={false}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <CustomFileUploadButton onClick={() => fileInputRef.current?.click()}>
          {fileName ? "Cambiar archivo" : "Subir archivo"}
        </CustomFileUploadButton>
        {fileName && <FileNameDisplay>{fileName}</FileNameDisplay>}
      </>
    );

    const renderStandardInput = () => (
      <StyledInput ref={ref} type={type} {...props} onChange={onChange} />
    );

    return (
      <InputWrapper>
        <Label>{label}</Label>
        {type === "file" ? renderFileInput() : renderStandardInput()}
      </InputWrapper>
    );
  }
);

export default Input;
