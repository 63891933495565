import { Gap } from "./components/Gap";

const TermsAndConditions = () => {
  return (
    <div className="flex flex-col ">
      <h1 className="text-2xl text-center">Términos y Condiciones</h1>
      <Gap s={24} />
      <p>
        Lorem ipsum dolor sit amet consectetur adipisi cing elit. Sint,
        temporibus. Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Sint, temporibus. Lorem ipsum dolor sit amet consectetur adipisicing
        elit. Sint, tem poribus. Lorem ipsum dolor sit amet consectetur
        adipisicing elit. Sint, temporibus.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisi cing elit. Sint,
        temporibus. Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Sint, temporibus. Lorem ipsum dolor sit amet consectetur adipisicing
        elit. Sint, tem poribus. Lorem ipsum dolor sit amet consectetur
        adipisicing elit. Sint, temporibus.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisi cing elit. Sint,
        temporibus. Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Sint, temporibus. Lorem ipsum dolor sit amet consectetur adipisicing
        elit. Sint, tem poribus. Lorem ipsum dolor sit amet consectetur
        adipisicing elit. Sint, temporibus.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisi cing elit. Sint,
        temporibus. Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Sint, temporibus. Lorem ipsum dolor sit amet consectetur adipisicing
        elit. Sint, tem poribus. Lorem ipsum dolor sit amet consectetur
        adipisicing elit. Sint, temporibus.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisi cing elit. Sint,
        temporibus. Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Sint, temporibus. Lorem ipsum dolor sit amet consectetur adipisicing
        elit. Sint, tem poribus. Lorem ipsum dolor sit amet consectetur
        adipisicing elit. Sint, temporibus.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisi cing elit. Sint,
        temporibus. Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Sint, temporibus. Lorem ipsum dolor sit amet consectetur adipisicing
        elit. Sint, tem poribus. Lorem ipsum dolor sit amet consectetur
        adipisicing elit. Sint, temporibus.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisi cing elit. Sint,
        temporibus. Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Sint, temporibus. Lorem ipsum dolor sit amet consectetur adipisicing
        elit. Sint, tem poribus. Lorem ipsum dolor sit amet consectetur
        adipisicing elit. Sint, temporibus.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisi cing elit. Sint,
        temporibus. Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Sint, temporibus. Lorem ipsum dolor sit amet consectetur adipisicing
        elit. Sint, tem poribus. Lorem ipsum dolor sit amet consectetur
        adipisicing elit. Sint, temporibus.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisi cing elit. Sint,
        temporibus. Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Sint, temporibus. Lorem ipsum dolor sit amet consectetur adipisicing
        elit. Sint, tem poribus. Lorem ipsum dolor sit amet consectetur
        adipisicing elit. Sint, temporibus.
      </p>
    </div>
  );
};

export default TermsAndConditions;
