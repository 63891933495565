import axios from "axios";

export const validateOTP =
  (type: "email" | "phone") =>
  async ({ code, inquiryId }: { code: string; inquiryId: string }) => {
    const response = await axios.post(
      `/verifications/${type}/validate-otp/${inquiryId}`,
      {
        code,
      }
    );

    return response.data;
  };
