import { Client } from "persona";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import RingLoader from "react-spinners/RingLoader";
import { Color } from "../../styles";
const Persona = require("persona");

interface Verfication extends View {
  config: PersonaConfig | null;
}

const Verfication = ({ handleNext, config }: Verfication) => {
  const [showLogoMask, setShowLogoMask] = useState(false);
  const client = useRef<Client | null>(null);

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.style.setProperty("display", "block", "important");
    }
  }, [showLogoMask]);

  const onReady = () => {
    client.current?.open();
  };

  const onComplete = ({ inquiryId, status, fields }: VerificationResult) => {
    handleNext({ inquiryId, status, fields });
  };

  const onCancel = ({
    inquiryId,
    sessionToken,
  }: {
    inquiryId: string;
    sessionToken: string;
  }) => {};

  const onError = (error: any) => console.log(error);

  type Meta = {
    name: string;
    path: string;
    metadata: {
      pageHeight: number;
    };
    inquiryId: string;
  };

  const onEvent = (name: string, meta: Meta) => {
    switch (name) {
      case "page-change":
        handlePageChange(meta.name);
        break;
      default:
        return;
    }
  };

  const handlePageChange = (name: string) => {
    switch (name) {
      case "start_biometric_a6cea0_start":
      case "gov_id_f8807a_country_select":
        setShowLogoMask(true);
        break;
      default:
        setShowLogoMask(false);
    }
    if (name.includes("start") || name.includes("country_select")) {
      setShowLogoMask(true);
    }
  };

  useEffect(() => {
    if (!config) return;
    client.current = new Persona.Client({
      ...config,
      onReady,
      onComplete,
      onCancel,
      onError,
      onEvent,
    });
  }, [config]);

  return (
    <div className="w-full h-full flex justify-center items-center">
      <RingLoader
        color={Color.secondary}
        loading={true}
        size={75}
        aria-label="Loading Spinner"
        data-testid="loader"
      />

      {showLogoMask &&
        createPortal(
          <div
            ref={divRef}
            className="w-full h-[70px] bg-white fixed bottom-0 left-0 right-0 block-important"
            style={{ zIndex: 9999 }}
          ></div>,
          document.body
        )}
    </div>
  );
};

export default Verfication;
