import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import illustration from "../../assets/img/work_details.png";
import Checkbox from "../../utils/components/Checkbox";
import { Gap } from "../../utils/components/Gap";
import Input from "../../utils/components/Input";
import ToggleGroup from "../../utils/components/ToggleGroup";
import Layout from "../Layout";
import UserProfileClass from "../Verification/UserProfile";
import { getFileInputRegister } from "../shared/utils";

interface WorkDetails extends View {
  updateData: (value: any) => void;
  verificationResult: VerificationResult;
}

const WorkDetails = ({
  handleNext,
  updateData,
  verificationResult,
}: WorkDetails) => {
  const [toggleOption, setToggleOption] = useState("");
  const [hasPatent, setHasPatent] = useState(false);
  const [hasNoName, setHasNoName] = useState(false);

  const defaultValues = {
    name: "" as string,
    patent: "" as string,
  } as const;

  const { register, watch, setValue } = useForm<typeof defaultValues>({
    defaultValues,
    mode: "onChange",
  });

  const name = watch("name");
  const patent = watch("patent");

  useEffect(() => {
    const hasPatent = toggleOption === "true";
    setHasPatent(hasPatent);
  }, [toggleOption]);

  useEffect(() => {
    if (hasNoName) {
      const user = new UserProfileClass(
        verificationResult.fields,
        verificationResult.inquiryId
      );
      setValue("name", user.name + " " + user.lastName);
    } else {
      setValue("name", "");
    }
  }, [hasNoName]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];
    if (file) {
      console.log("File uploaded:", file);
    }
  };

  const fileInputRegister = (name: string) =>
    getFileInputRegister(register, name, handleFileChange);

  const options = [
    { value: "true", node: "Tengo una patente" },
    { value: "false", node: "No tengo patente" },
  ];

  const content = (
    <div className="w-full">
      <p>Comparte unos datos más de tu trabajo.</p>
      <Gap s={24} />
      <Input
        label="Nombre del lugar donde trabajas"
        placeholder="Si trabajas en un local, escribe el nombre aquí"
        disabled={hasNoName}
        {...register("name")}
      />
      <Checkbox
        label={"Trabajo por mi cuenta"}
        onCheckedChange={() => setHasNoName(!hasNoName)}
        defaultChecked={hasNoName}
      />
      <Gap s={24} />
      <Gap s={24} />
      <div className="w-4/5 flex flex-col mx-auto">
        <ToggleGroup
          options={options}
          onValueChange={setToggleOption}
          value={toggleOption}
          label={"¿Tienes una patente de comercio?"}
        />
      </div>
      <div className={`${!hasPatent ? "h-0 invisible" : ""}`}>
        <Gap s={24} />
        <Input
          label="Patente de comercio"
          type="file"
          {...fileInputRegister("patent")}
        />
      </div>
    </div>
  );

  const onNext = () => {
    updateData({ name, patent });
    handleNext();
  };

  const disabled = !name || (hasPatent && !patent);

  const button = {
    onNext,
    text: "Confirmar",
    disabled,
  };

  return (
    <Layout
      illustration={illustration}
      title="Datos de tu establecimiento"
      content={content}
      button={button}
    />
  );
};

export default WorkDetails;
