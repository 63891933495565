import { useEffect, useState } from "react";
import "./App.css";
import Persona from "./utils/Persona";
import Accountant from "./views/Accountant";
import Address from "./views/Address";
import Bracket from "./views/Bracket";
import Email from "./views/Email";
import Landing from "./views/Landing";
import Phone from "./views/Phone";
import Profile from "./views/Profile";
import Success from "./views/Success";
import Verfication from "./views/Verification";
import WorkDetails from "./views/WorkDetails";
import WorkType from "./views/WorkType";

const screens = {
  landing: "landing",
  verification: "verification",
  profile: "profile",
  address: "address",
  work_details: "work_details",
  work_type: "work_type",
  accountant: "accountant",
  bracket: "bracket",
  email: "email",
  phone: "phone",
  success: "success",
};

function App() {
  const [view, setView] = useState<keyof typeof screens>("landing");
  const [verificationResult, setVerificationResult] = useState(
    {} as VerificationResult
  );
  const [userFilledData, setUserFilledData] = useState({} as UserProfile);
  const [personaConfig, setPersonaConfig] = useState<PersonaConfig | null>(
    null
  );
  const [flowType, setFlowType] = useState<"admin" | "normal">("normal");

  useEffect(() => {
    const getConfig = async () => {
      const config = await Persona.getConfig();
      return config;
    };

    getConfig().then((config) => setPersonaConfig(config));
  }, []);

  const handleNext = {
    landing: () => setView("verification"),
    verification: (verificationResult: VerificationResult) => {
      setVerificationResult(verificationResult);
      setView("profile");
    },
    profile: () => setView("address"),
    address: () => setView(flowType === "admin" ? "email" : "work_details"),
    // only in normal = START
    work_details: () => setView("work_type"),
    work_type: () => setView("bracket"),
    bracket: () => setView("accountant"),
    accountant: () => setView("email"),
    // only in normal = END
    email: () => setView("phone"),
    phone: () => setView("success"),
  };

  const updateData = (key: string) => (value: any) => {
    setUserFilledData((prev: any) => ({ ...prev, [key]: value }));
  };

  const views = {
    landing: (
      <Landing
        handleNext={handleNext["landing"]}
        setFlowType={setFlowType}
        flowType={flowType}
      />
    ),
    verification: (
      <Verfication
        handleNext={handleNext["verification"]}
        config={personaConfig}
      />
    ),
    profile: (
      <Profile
        verificationResult={verificationResult}
        handleNext={handleNext["profile"]}
      />
    ),
    address: (
      <Address
        handleNext={handleNext["address"]}
        updateData={updateData("address")}
      />
    ),
    work_details: (
      <WorkDetails
        handleNext={handleNext["work_details"]}
        updateData={updateData("work_details")}
        verificationResult={verificationResult}
      />
    ),
    work_type: (
      <WorkType
        handleNext={handleNext["work_type"]}
        updateData={updateData("work_type")}
      />
    ),
    bracket: (
      <Bracket
        handleNext={handleNext["bracket"]}
        updateData={updateData("bracket")}
      />
    ),
    accountant: (
      <Accountant
        handleNext={handleNext["accountant"]}
        updateData={updateData("accountant")}
      />
    ),
    email: (
      <Email
        handleNext={handleNext["email"]}
        inquiryId={verificationResult.inquiryId}
        updateData={updateData("email")}
      />
    ),
    phone: (
      <Phone
        handleNext={handleNext["phone"]}
        inquiryId={"verificationResult.inquiryId"}
        updateData={updateData("phone")}
      />
    ),
    success: (
      <Success
        verificationResult={verificationResult}
        userFilledData={userFilledData}
      />
    ),
  };

  return (
    <div className="App">
      {/* @ts-ignore */}
      {views[view]}
    </div>
  );
}

export default App;
