import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  button {
    width: 100%;
    height: 100%;
    padding: 0.75rem;
  }
`;
