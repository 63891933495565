import * as RadixDialog from "@radix-ui/react-dialog";
import styled, { keyframes } from "styled-components";

const overlayShow = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const contentShow = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`;

export const StyledDialogOverlay = styled(RadixDialog.Overlay)`
  animation: ${overlayShow} 0.3s ease-in-out;
  background: rgba(0, 0, 0, 0.2);
`;

export const StyledDialogContent = styled(RadixDialog.Content)`
  animation: ${contentShow} 0.3s ease-in-out;
`;
