import * as RadixCheckbox from "@radix-ui/react-checkbox";
import { CheckIcon } from "@radix-ui/react-icons";
import { ReactElement } from "react";
import { Gap } from "../Gap";

interface Checkbox {
  label: string | ReactElement;
  defaultChecked?: boolean;
  onCheckedChange?: (x: any) => void;
}
const Checkbox = ({
  label,
  defaultChecked = false,
  onCheckedChange,
}: Checkbox) => (
  <form>
    <div style={{ display: "flex", alignItems: "center" }}>
      <RadixCheckbox.Root
        className="CheckboxRoot"
        defaultChecked={defaultChecked}
        onCheckedChange={onCheckedChange}
      >
        <RadixCheckbox.Indicator className="CheckboxIndicator">
          <CheckIcon />
        </RadixCheckbox.Indicator>
      </RadixCheckbox.Root>
      <div className="flex">
        <Gap s={24} />
        <label htmlFor="c1">{label}</label>
      </div>
    </div>
  </form>
);

export default Checkbox;
