export const getFileInputRegister = (
  register: any,
  name: string,
  fileChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void
) => {
  const { onChange, ...rest } = register(name);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    fileChangeHandler(event);
    onChange(event);
  };

  return { ...rest, onChange: handleChange };
};
