export const Color = {
  primary: "#2E5BFF",
  secondary: "#666AF6",
  danger: "#EB5757",
  success: "#219653",
  warning: "#F2C94C",
  dark: "#000000",
  light: "#FFFFFF",
  grey: "#828282",
  greyLight: "#F2F2F2",
  greyLighter: "#FAFAFA",
  greyLightest: "#E0E0E0",
  black: "#333333",
  blackLight: "#4F4F4F",
  blackLighter: "#828282",
  blackLightest: "#BDBDBD",
  white: "#FFFFFF",
  whiteDark: "#F2F2F2",
  whiteDarker: "#E0E0E0",
  whiteDarkest: "#BDBDBD",
  transparent: "transparent",
};
