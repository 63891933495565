import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import RingLoader from "react-spinners/RingLoader";
import illustration from "../../assets/img/address.png";
import { Color } from "../../styles";
import Checkbox from "../../utils/components/Checkbox";
import { Gap } from "../../utils/components/Gap";
import Input from "../../utils/components/Input";
import Layout from "../Layout";
import { getFileInputRegister } from "../shared/utils";

const api_key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;

const containerStyle = {
  width: "100%",
  height: "400px",
};

const defaultCenter = {
  lat: 14.6349,
  lng: -90.5069,
};

interface Address extends View {
  updateData: (value: any) => void;
}

const text1 =
  "Sube una imagen de un recibo o factura de servicios que indique tu dirección.";

const text2 = "Puedes subir recibos de:";

const list = ["Agua", "Luz", "Teléfono (línea fija)", "Internet"];

const Address = ({ handleNext, updateData }: Address) => {
  const [checked, setChecked] = React.useState(true);
  const [workCache, setWorkCache] = React.useState({
    address: "",
    file: null as File | null,
  });

  const [marker, setMarker] = useState<JSX.Element | null>(null);
  const [position, setPosition] = useState(defaultCenter);
  const [zoom, setZoom] = useState(10);
  const [address, setAddress] = useState("");
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: api_key,
  });

  const firstUncheck = React.useRef(true);

  const defaultValues = {
    homeAddress: "" as string,
    workAddress: "" as string,
    homeFile: null as File | null,
    workFile: null as File | null,
  } as const;

  const { register, watch, setValue, getValues } = useForm<
    typeof defaultValues
  >({
    defaultValues,
    mode: "onChange",
  });

  const homeAddress = watch("homeAddress");
  const homeFile = watch("homeFile");
  const workFile = watch("workFile");

  useEffect(() => {
    if (checked) {
      const { workAddress, workFile } = getValues();
      const workCache = {
        address: workAddress,
        file: workFile,
      };
      setWorkCache(workCache);

      const { homeAddress, homeFile } = getValues();
      setValue("workAddress", homeAddress);
      setValue("workFile", homeFile);
    } else {
      if (firstUncheck.current) {
        setWorkCache({ address: "", file: null });
        firstUncheck.current = false;
        setValue("workAddress", "");
        setValue("workFile", null);
        return;
      }
      const { address, file } = workCache;
      setValue("workAddress", address);
      setValue("workFile", file);
    }
  }, [checked, homeAddress, homeFile]);

  useEffect(() => {
    setValue("homeAddress", address);
  }, [address]);

  useEffect(() => {
    if (!isLoaded) return;
    const marker = (
      <Marker
        position={position}
        draggable={true}
        onDragEnd={onMarkerDragEnd}
      />
    );
    setMarker(marker);
  }, [isLoaded, position]);

  useEffect(() => {
    if (!navigator.geolocation) {
      console.error("Error: Your browser doesn't support geolocation.");
      return;
    }
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        setZoom(15);
      },
      () => {
        console.error("Error: The Geolocation service failed.");
      }
    );
  }, []);

  useEffect(() => {
    reverseGeocode(position);
  }, [position]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];
    if (file) {
      console.log("File uploaded:", file);
    }
  };

  const onNext = () => {
    updateData({ homeFile, workFile });
    handleNext();
  };

  const fileInputRegister = (name: string) =>
    getFileInputRegister(register, name, handleFileChange);

  const onMarkerDragEnd = useCallback((event: google.maps.MapMouseEvent) => {
    const newLat = event.latLng!.lat();
    const newLng = event.latLng!.lng();
    setPosition({ lat: newLat, lng: newLng });
  }, []);

  const reverseGeocode = async (latLng: google.maps.LatLngLiteral) => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat},${latLng.lng}&key=${api_key}`
    );
    const data = await response.json();
    setAddress(
      data.results[0]?.formatted_address || "No se encontró la dirección"
    );
  };

  const content = (
    <div>
      <Gap s={24} />
      {isLoaded ? (
        <>
          <p>Mueve el pin en el mapa para que llene tu dirección.</p>
          <p>Si el mapa no te da la dirección correcta, puedes escribirla.</p>
          <Gap s={4} />
        </>
      ) : null}
      <div>
        <div
          style={containerStyle}
          className="flex justify-center items-center"
        >
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={position}
              zoom={zoom}
            >
              <div>{marker}</div>
            </GoogleMap>
          ) : (
            <RingLoader color={Color.secondary} loading={true} size={75} />
          )}
        </div>
        <Gap s={16} />
        <Input label="Dirección donde vives" {...register("homeAddress")} />
        <p className="text">{text1}</p>
        <Gap s={8} />
        <p className="text">{text2}</p>
        <ul style={{ listStyle: "inside", paddingLeft: "8px" }}>
          {list.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
          <Gap s={16} />
        </ul>
        <Input
          label="Recibo o factura (hogar)"
          type="file"
          {...fileInputRegister("homeFile")}
        />
        <Gap s={16} />
        <Checkbox
          label={"Mi dirección de trabajo es la misma que la de mi casa"}
          onCheckedChange={() => setChecked(!checked)}
          defaultChecked={checked}
        />
        <Gap s={24} />
        <Input
          label="Dirección donde trabajas"
          disabled={checked}
          {...register("workAddress")}
        />
        <div className={`w-full ${checked ? "h-0 invisible" : ""}`}>
          <Input
            label="Recibo o factura (trabajo)"
            type="file"
            {...fileInputRegister("workFile")}
          />
        </div>
      </div>
    </div>
  );

  const disabled = checked ? !homeFile : !homeFile || !workFile;

  const button = {
    onNext,
    text: "Confirmar",
    disabled,
  };

  return (
    <Layout
      illustration={illustration}
      title="Dirección fiscal"
      button={button}
      content={content}
    />
  );
};

export default Address;
