import { useEffect, useState } from "react";
import { Gap } from "../../utils/components/Gap";
import Input from "../../utils/components/Input";
import Layout from "../Layout";
import { ProfileImageStyleWrapper } from "../Profile";
import UserProfileClass from "../Verification/UserProfile";

interface Success {
  verificationResult: VerificationResult;
  userFilledData: any;
}

const loadingGif =
  "https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif";

const Success = ({ verificationResult, userFilledData }: Success) => {
  const [profilePicture, setProfilePicture] = useState(loadingGif);

  const user = new UserProfileClass(
    verificationResult.fields,
    verificationResult.inquiryId
  );

  useEffect(() => {
    const updateProfilePicture = async () => {
      const picture = await user.fetchProfilePicture();
      setProfilePicture(picture);
    };

    updateProfilePicture();
  }, []);

  const fields = [
    {
      label: "Nombres",
      value: user.name,
    },
    {
      label: "Apellidos",
      value: user.lastName,
    },
    {
      label: "Email",
      value: userFilledData.email,
    },
    {
      label: "Celular",
      value: userFilledData.phone,
    },
    {
      label: "Dirección",
      value: userFilledData.address,
    },
    {
      label: "Tipo de trabajo",
      value: userFilledData.work_type,
    },
  ];

  const content = (
    <div className="flex flex-col">
      <p>
        Tenemos toda la información necesaria. Te notificaremos en 3 a 5 dias
        hábiles cuando tu NIT y Agencia Virtual esté lista
      </p>
      <Gap s="24" />
      {fields.map(({ label, value }) => (
        // @ts-ignore
        <Input label={label} value={value} disabled />
      ))}
    </div>
  );

  const button = {
    onNext: () => window.close(),
    text: "Finalizar",
    disabled: false,
  };

  return (
    <ProfileImageStyleWrapper>
      <Layout
        title="¡Gracias por registrarte!"
        content={content}
        button={button}
        illustration={profilePicture}
      />
    </ProfileImageStyleWrapper>
  );
};

export default Success;
