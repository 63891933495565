import { useState } from "react";
import { useForm } from "react-hook-form";
import illustration from "../../assets/img/accountant.png";
import { Gap } from "../../utils/components/Gap";
import ToggleGroup from "../../utils/components/ToggleGroup";
import Layout from "../Layout";

interface Bracket extends View {
  updateData: (value: any) => void;
}

const bracketName = {
  peq: "Pequeño contribuyente",
  gen: "General",
};

const Bracket = ({ handleNext, updateData }: Bracket) => {
  const [bracket, setBracket] = useState("peq");

  const defaultValues = {
    name: "" as string,
    nit: "" as string,
  } as const;

  const { register, watch } = useForm<typeof defaultValues>({
    defaultValues,
    mode: "onChange",
  });

  const name = watch("name");
  const nit = watch("nit");

  const options = [
    { value: "peq", node: "Menos de Q150,000" },
    { value: "gen", node: "Más de Q150,000" },
  ];

  const content = (
    <div className="w-full">
      <p>Descubre tu régimen de impuestos</p>
      <Gap s={24} />
      <div className="w-full flex flex-col mx-auto">
        <ToggleGroup
          options={options}
          onValueChange={setBracket}
          value={bracket}
          label={"¿Cuánto vendes en un año?"}
        />
      </div>
      <Gap s={54} />
      <div className="flex flex-col items-center justify-start">
        <p>Tu régimen es:</p>
        <h1 className="text-2xl">
          {bracketName[bracket as keyof typeof bracketName]}
        </h1>
      </div>
    </div>
  );

  const onNext = () => {
    updateData({ name, nit });
    handleNext();
  };
  const disabled = bracket === "";
  const button = {
    onNext,
    text: "Confirmar",
    disabled,
  };

  return (
    <Layout
      illustration={illustration}
      title="Régimen de impuestos"
      content={content}
      button={button}
    />
  );
};

export default Bracket;
