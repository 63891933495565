import styled from "styled-components";
import { Color } from "../../../styles";

export const StyledInput = styled.input`
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;

  &:focus {
    border-color: ${Color.secondary};
    outline: none;
  }
`;

export const CustomFileUploadButton = styled.button`
  text-align: center;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #0056b3;
  }
`;

export const FileNameDisplay = styled.span`
  display: inline-block;
  padding: 8px 12px;
  margin-top: 10px;
  background-color: #f0f0f0;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  border: 1px solid #dcdcdc;
  word-break: break-all; // Helps with long file names

  &:before {
    content: "Archivo cargado: ";
    font-weight: bold;
  }
`;
