import { Dispatch, SetStateAction, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../Button";
import Input from "../Input";

type ValidateCode = {
  validateCode: ({
    code,
    inquiryId,
  }: {
    code: string;
    inquiryId: string;
  }) => Promise<{ error: string }>;
  inquiryId: string;
  isCodeValid: boolean;
  setIsCodeValid: Dispatch<SetStateAction<boolean>>;
  attemptsLeft: number;
  setAttemptsLeft: Dispatch<SetStateAction<number>>;
};

const ValidateCode = ({
  validateCode: validateOTP,
  inquiryId,
  isCodeValid,
  setIsCodeValid,
  attemptsLeft,
  setAttemptsLeft,
}: ValidateCode) => {
  const [isValidating, setIsValidating] = useState(false);

  const { register, watch } = useForm();
  const code = watch("code");

  const validateCode = async () => {
    setIsValidating(true);
    try {
      const response = await validateOTP({ code, inquiryId });
      if (!response || response.error) {
        const error = response?.error ?? "Error al validar el código";
        throw Error(error);
      }

      setIsCodeValid(true);
    } catch (error) {
      // snackbar error
      setAttemptsLeft((prev) => prev - 1);
    } finally {
      setIsValidating(false);
    }
  };

  return (
    <>
      <Input
        label="Codigo"
        placeholder="Ingresa el código"
        disabled={isCodeValid}
        {...register("code")}
      />
      <Button
        variant="outline"
        onClick={validateCode}
        disabled={!attemptsLeft || isCodeValid || code?.length !== 6}
        isLoading={isValidating}
        size={"2"}
      >
        {isCodeValid ? "Código correcto" : "Validar código"}
      </Button>
      {!isCodeValid && attemptsLeft <= 3 && attemptsLeft > 0 && (
        <p>{`Te quedan ${attemptsLeft} intentos`}</p>
      )}
      {!attemptsLeft && <p>Se acabaron los intentos</p>}
    </>
  );
};
export default ValidateCode;
