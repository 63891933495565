import * as RadixDialog from "@radix-ui/react-dialog";
import React from "react";
import { StyledDialogContent, StyledDialogOverlay } from "./style";

export const cardClassName = "bg-white rounded-lg shadow-xl p-6 pr-3 pt-3";

interface DialogProps {
  children?: React.ReactNode;
  content: JSX.Element | undefined;
  contentClassName?: string;
  asChild?: boolean;
  contentStyle?: React.CSSProperties;
}

const Dialog: React.FC<DialogProps> = ({
  children,
  content,
  contentClassName,
  contentStyle,
}) => (
  <RadixDialog.Root>
    <RadixDialog.Trigger>{children}</RadixDialog.Trigger>
    <RadixDialog.Portal>
      <StyledDialogOverlay className="w-full h-full fixed top-0 left-0 absolute z-50" />
      <StyledDialogContent
        className={
          cardClassName +
            " fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 " +
            "min-h-[400px] " +
            contentClassName ?? ""
        }
        style={contentStyle}
      >
        <RadixDialog.Title />
        <RadixDialog.Description />
        <RadixDialog.Close className="flex ml-auto sticky top-0">
          {/* <Icon name={Icons.X} /> */}X
        </RadixDialog.Close>
        {content}
      </StyledDialogContent>
    </RadixDialog.Portal>
  </RadixDialog.Root>
);

export default Dialog;
