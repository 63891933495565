import Persona from "../../utils/Persona";

class UserProfileClass {
  private userProfile: UserProfile;
  private inquiryId: string;
  private _profilePicture: string = "";

  constructor(userProfile: UserProfile, inquiryId: string) {
    this.userProfile = userProfile;
    this.inquiryId = inquiryId;
  }

  public async fetchProfilePicture(): Promise<string> {
    return await Persona.getProfilePicture(this.inquiryId);
  }

  private static capitalize(str: string | null): string {
    if (!str) return "";
    const strArr = str.split(" ");
    const capitalizedArr = strArr.map(str => {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    });
    return capitalizedArr.join(" ");
  }

  private getName(): string {
    const firstName = UserProfileClass.capitalize(
      this.userProfile["name-first"].value
    );
    const middleName = UserProfileClass.capitalize(
      this.userProfile["name-middle"].value
    );

    return (firstName ?? "") + (middleName ? ` ${middleName}` : "");
  }

  private getLastName(): string {
    const lastName = UserProfileClass.capitalize(
      this.userProfile["name-last"].value ?? ""
    );
    return lastName;
  }

  get name(): string {
    const name = this.getName();
    return name;
  }

  get lastName(): string {
    const lastName = this.getLastName();
    return lastName;
  }

  get profilePicture(): string {
    return this._profilePicture;
  }
}

export default UserProfileClass;
