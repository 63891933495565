import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { requestOTP } from "../../api/requestOTP";
import { validateOTP } from "../../api/validateOTP";
import illustration from "../../assets/img/phone.png";
import Button from "../../utils/components/Button";
import { Gap } from "../../utils/components/Gap";
import Input from "../../utils/components/Input";
import ValidateCode from "../../utils/components/ValidateCode";
import Layout from "../Layout";

interface Phone extends View {
  inquiryId: string;
  updateData: (value: any) => void;
}

const storedCodeRequestsLeft = parseInt(
  localStorage.getItem("codeRequestsLeft") ?? "5"
);

const Phone = ({ handleNext, inquiryId, updateData }: Phone) => {
  const [isOTPRequested, setOTPRequested] = useState(false);
  const [buttonText, setButtonText] = useState("Pedir código");
  const [timer, setTimer] = useState(30);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [isCodeValid, setIsCodeValid] = useState(false);
  const [attemptsLeft, setAttemptsLeft] = useState(5);
  const [codeRequestsLeft, setCodeRequestsLeft] = useState(
    storedCodeRequestsLeft
  );
  const [isRequesting, setIsRequesting] = useState(false);

  const { register, watch } = useForm();
  const phoneNumber = watch("phoneNumber");

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isOTPRequested && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setButtonText("Reenviar código");
      // @ts-ignore
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isOTPRequested, timer]);

  useEffect(() => {
    localStorage.setItem("codeRequestsLeft", codeRequestsLeft.toString());
    return () => localStorage.removeItem("codeRequestsLeft");
  }, [codeRequestsLeft]);

  const isValidPhoneNumber = (phone: string) => {
    function isValidGuatemalanPhoneNumber(number: string) {
      const pattern = /^(?:\+502 ?)?[2-7]\d{3}-?\d{4}$/;
      return pattern.test(number);
    }

    return isValidGuatemalanPhoneNumber(phone);
  };

  const handleRequestOTP = async () => {
    setIsRequesting(true);
    try {
      const requested = await requestOTP("phone")({
        phone: phoneNumber,
        inquiryId,
      });
      if (!requested || requested.error) {
        const error = requested?.error ?? "Error al solicitar el código";
        throw Error(error);
      }

      setCodeRequestsLeft((prev) => prev - 1);
      setAttemptsLeft(5);
      setOTPRequested(true);
      setButtonText("Pedir código nuevamente");
      setButtonDisabled(true);
      let countDown = 30;
      const interval = setInterval(() => {
        setTimer(countDown--);
        if (countDown < 0) {
          clearInterval(interval);
          setButtonDisabled(false);
        }
      }, 1000);
    } catch (error) {
      console.log(error);
      // snackbar error
    } finally {
      setIsRequesting(false);
    }
  };

  const onNext = () => {
    updateData(phoneNumber);
    handleNext();
  };
  const content = (
    <div className="flex flex-col">
      <p>
        Ingresa tu numero de teléfono. Te enviaremos un SMS con un código para
        confirmar
      </p>
      <Gap s={24} />
      <Input
        label="Número de teléfono"
        placeholder="Número de teléfono"
        disabled={isCodeValid}
        {...register("phoneNumber")}
      />
      <Button
        variant="outline"
        onClick={handleRequestOTP}
        disabled={
          !isValidPhoneNumber(phoneNumber) ||
          !codeRequestsLeft ||
          isButtonDisabled ||
          isCodeValid
        }
        isLoading={isRequesting}
        size={"2"}
      >
        {buttonText} {timer > 0 && `(${timer})`}
      </Button>
      {!codeRequestsLeft && (
        <p>Agostaste los códigos que puedes pedir. Por favor, intenta mañana</p>
      )}
      <Gap s={24} />
      {isOTPRequested ? (
        <ValidateCode
          validateCode={validateOTP("phone")}
          inquiryId={inquiryId}
          isCodeValid={isCodeValid}
          setIsCodeValid={setIsCodeValid}
          attemptsLeft={attemptsLeft}
          setAttemptsLeft={setAttemptsLeft}
        />
      ) : null}
    </div>
  );

  const button = {
    text: "Continuar",
    disabled: !isCodeValid,
    onNext,
  };
  return (
    <Layout
      title="Teléfono"
      content={content}
      button={button}
      illustration={illustration}
    />
  );
};

export default Phone;
