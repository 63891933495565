import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import illustration from "../../assets/img/accountant.png";
import { Gap } from "../../utils/components/Gap";
import Input from "../../utils/components/Input";
import ToggleGroup from "../../utils/components/ToggleGroup";
import Layout from "../Layout";

interface Accountant extends View {
  updateData: (value: any) => void;
}

const Accountant = ({ handleNext, updateData }: Accountant) => {
  const [toggleOption, setToggleOption] = useState("");
  const [hasAccountant, setHasAccountant] = useState(false);

  const defaultValues = {
    name: "" as string,
    nit: "" as string,
  } as const;

  const { register, watch } = useForm<typeof defaultValues>({
    defaultValues,
    mode: "onChange",
  });

  const name = watch("name");
  const nit = watch("nit");

  const options = [
    { value: "true", node: "Si" },
    { value: "false", node: "No" },
  ];

  useEffect(() => {
    const hasAccountant = toggleOption === "true";
    setHasAccountant(hasAccountant);
  }, [toggleOption]);

  const content = (
    <div className="w-full">
      <p>Si tienes un contador, llena sus datos aquí.</p>
      <Gap s={24} />
      <div className="w-3/5 flex flex-col mx-auto">
        <ToggleGroup
          options={options}
          onValueChange={setToggleOption}
          value={toggleOption}
          label={"¿Tienes un contador?"}
        />
      </div>
      <div className={`${!hasAccountant ? "h-0 opacity-0" : ""}`}>
        <Gap s={24} />
        <Input
          label="Nombre"
          placeholder="Llena el nombre de tu contador"
          {...register("name")}
        />
        <Gap s={24} />
        <Input
          label="NIT"
          placeholder="Ingresa el número de nit de tu contador"
          {...register("nit")}
        />
      </div>
    </div>
  );

  const onNext = () => {
    updateData({ name, nit });
    handleNext();
  };

  const disabled =
    toggleOption === "" || (hasAccountant ? !name || !nit : false);

  const button = {
    onNext,
    text: "Confirmar",
    disabled,
  };

  return (
    <Layout
      illustration={illustration}
      title="Contador"
      content={content}
      button={button}
    />
  );
};

export default Accountant;
