import { useState } from "react";
import illustration from "../../assets/img/landing.png";
import TermsAndConditions from "../../utils/TermsAndConditions";
import Checkbox from "../../utils/components/Checkbox";
import Dialog from "../../utils/components/Dialog";
import { Gap } from "../../utils/components/Gap";
import ToggleGroup from "../../utils/components/ToggleGroup";
import Layout from "../Layout";

interface Landing {
  handleNext: () => void;
  setFlowType: (flowType: "admin" | "normal") => void;
  flowType: "admin" | "normal";
}

const permissionLabel =
  "Doy permiso para que Tributax, S.A. gestione mis datos personales para formalizarme ante la SAT";

const Landing = ({ handleNext, setFlowType, flowType }: Landing) => {
  const [checks, setChecks] = useState({
    permission: false,
    termsAndConditions: false,
  });

  const TermsAndConditionsLabel = () => {
    return (
      <p>
        Confirmo que he leído y acepto los{" "}
        <Dialog
          content={<TermsAndConditions />}
          contentStyle={{
            width: "calc(100vw - 32px)",
            maxHeight: "calc(100vh - 64px)",
            overflow: "scroll",
          }}
        >
          <p className="text-violet11">Términos y Condiciones</p>
        </Dialog>
      </p>
    );
  };

  const onCheckedChange = (checkbox: string) => (checked: boolean) => {
    setChecks((checks) => ({ ...checks, [checkbox]: checked }));
  };

  const flowOptions = [
    {
      value: "admin",
      node: "Administrativo",
    },
    {
      value: "normal",
      node: "Con obligaciones",
    },
  ];

  const content = (
    <div className="flex flex-col flex-grow">
      <p className="text-center">Con el apoyo de Tributax</p>
      <Gap s={32} />
      <Checkbox
        label={permissionLabel}
        onCheckedChange={onCheckedChange("permission")}
      />
      <Gap s={16} />
      <Checkbox
        label={<TermsAndConditionsLabel />}
        onCheckedChange={onCheckedChange("termsAndConditions")}
      />
      <div className="flex-grow" />
      <ToggleGroup
        // @ts-expect-error
        onValueChange={setFlowType}
        options={flowOptions}
        value={flowType}
        label="Tipo de formalización"
      />
    </div>
  );

  const onNext = () => {
    handleNext();
  };

  const disabled = !checks.permission || !checks.termsAndConditions;

  const button = {
    onNext,
    text: "Comenzar",
    disabled,
  };

  return (
    <Layout
      title="Formalización"
      illustration={illustration}
      content={content}
      button={button}
    />
  );
};

export default Landing;
