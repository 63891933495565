import styled from "styled-components";
import Button from "../../utils/components/Button";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 1rem;
  padding-right: 0.5rem;
  overflow-y: scroll;
`;

export const Title = styled.h1`
  text-align: start;
  font-family: Rubik, sans-serif;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 32px;
  color: rgb(14, 14, 17);
`;

export const Illustration = styled.img`
  width: 100%;
  max-width: 280px;
  min-height: 180px;
  object-fit: contain;
`;

type NextButtonProps = {
  disabled?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
};

export const NextButton: React.FC<NextButtonProps> = props => {
  const Container = styled.div`
    margin-top: 2rem;
    margin-bottom: 1rem;
    width: 100%;
  `;
  return (
    <Container>
      <Button {...props} />
    </Container>
  );
};
