import axios from "axios";

export const requestOTP =
  <T extends "email" | "phone">(type: T) =>
  async (params: { [key in T]: string } & { inquiryId: string }) => {
    const { [type]: input, inquiryId } = params;

    const response = await axios.post(
      `/verifications/${type}/request-otp/${inquiryId}`,
      {
        [type]: input,
      }
    );

    return response.data;
  };
