import axios from "axios";

const Persona = {
  getConfig: async () => {
    try {
      const response = await axios.get("/verifications/config");
      return response.data;
    } catch (error) {
      return "";
    }
  },
  getProfilePicture: async (inquiryId: string) => {
    try {
      const response = await axios.get("/verifications/selfie/" + inquiryId);
      return response.data;
    } catch (error) {
      return "";
    }
  },
};

export default Persona;
